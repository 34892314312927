export const certificates = [
    {
        name: 'Basics of Data Analytics',
        description: 'CCE, Indian Institute of Science, Bangalore',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543637/assets/iisc_qfsswk.png',
        url: '',
        gradient: 'linear-gradient(45deg, #b92b27, #1565c0)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Introduction to Python',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Intermediate Python',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Python Data Science\nToolbox (Part 1)',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Python Data Science\nToolbox (Part 2)',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Introduction to Importing\nData in Python',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Introduction to Relational\nDatabases in SQL',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Intermediate Importing\nData in Python',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'pandas Foundations',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Analyzing Police Activity\nwith pandas',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Cleaning Data in Python',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Introduction to Data\nVisualization in Python',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Manipulating DataFrames\nwith pandas',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'data'
    },
    {
        name: 'Java and J2EE',
        description: 'Silicon Academy',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543638/assets/silicon_sqai4x.webp',
        url: '',
        gradient: 'linear-gradient(45deg, #b92b27, #1565c0)',
        from: 'June 2016',
        to: 'July 2016',
        group: 'web'
    },
    {
        name: 'Introduction to SQL',
        description: 'Datacamp',
        image: 'https://res.cloudinary.com/vishnu-dev/image/upload/v1617543636/assets/datacamp_poz3jj.png',
        url: '',
        gradient: 'linear-gradient(45deg, #9CECFB, #0052D4)',
        from: 'Aug 2019',
        to: 'Dec 2019',
        group: 'web'
    }
];
